import { keyBy } from 'lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import {
  configureApps,
  listApps,
} from '@wix/ambassador-social-groups-v1-group-app/http';
import {
  GroupApp,
  GroupAppKey,
} from '@wix/ambassador-social-groups-v1-group-app/types';
import { deleteGroup } from '@wix/ambassador-social-groups-v2-group/http';
import type {
  Group,
  GroupSettings,
} from '@wix/ambassador-social-groups-v2-group/types';
import { listRules } from '@wix/ambassador-social-groups-v2-group-rules/http';
import { getActivityStats } from '@wix/ambassador-social-groups-v1-activity-stats/http';
import { resetUpdatesCounter } from '@wix/ambassador-social-groups-v2-group-updates/http';
import { getJoinRequirements } from '@wix/ambassador-social-groups-v2-join-group-request/http';
import * as notificationSettings from '@wix/ambassador-social-groups-v1-notification-settings/http';
import type { GetJoinRequirementsRequest } from '@wix/ambassador-social-groups-v2-join-group-request/types';
import { ViolationType } from '@wix/ambassador-social-groups-v2-join-group-request/types';
import type {
  GetNotificationSettingsRequest,
  UpdateNotificationSettingsRequest,
} from '@wix/ambassador-social-groups-v1-notification-settings/types';
import {
  createOrReplaceAllMembershipQuestions,
  listMembershipQuestions,
} from '@wix/ambassador-social-groups-v2-membership-question/http';
import { CreateOrReplaceAllMembershipQuestionsRequest } from '@wix/ambassador-social-groups-v2-membership-question/types';
import { JoinRequest } from '@wix/ambassador-social-groups-v2-group-member/types';
import { queryEventsV2 } from '@wix/ambassador-events-v1-event/http';
import {
  EventFieldset,
  SortOrder,
} from '@wix/ambassador-events-v1-event/types';

import * as groups from 'api/groups';
import * as membership from 'api/membership';

import { GroupsRequestSort } from 'settings/consts';

import * as application from 'store/application';
import {
  selectCurrentUser,
  selectShareProfileConsent,
} from 'store/application/selectors';
import { selectGroupName } from 'store/groups/selectors';

import type { IRootState } from '../types';

import { ENABLED_TABS, ESortOptions, TABS_WHITELIST } from './constants';
import type {
  IJoinRequirements,
  IQueryGroupsParams,
  IUpdateGroupInfoParams,
} from './types';

export const resetActivityCounter = createAsyncThunk(
  'group:resetActivityCounter',
  async (groupId: string, thunkAPI) => {
    const flowApi = thunkAPI.extra as ControllerFlowAPI;

    try {
      await flowApi.httpClient.request(resetUpdatesCounter({ groupId }));
    } catch (err) {
      const error = err as Error;
      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const fetchActivity = createAsyncThunk(
  'group:activity:fetch',
  async function (groupId: string, thunkAPI) {
    const { dispatch } = thunkAPI;
    const flowApi = thunkAPI.extra as ControllerFlowAPI;
    const { translations } = flowApi;
    const { t } = translations;

    try {
      const { data } = await flowApi.httpClient.request(
        getActivityStats({ groupId }),
      );

      return data;
    } catch (err) {
      const error = err as Error;
      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.groups.activity.fetch'),
        }),
      );
      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const fetchRules = createAsyncThunk(
  'group:rules:fetch',
  async function (groupId: string, thunkAPI) {
    const { dispatch } = thunkAPI;
    const flowApi = thunkAPI.extra as ControllerFlowAPI;
    const { translations } = flowApi;
    const { t } = translations;

    try {
      const { data } = await flowApi.httpClient.request(listRules({ groupId }));

      return data.rules ?? [];
    } catch (err) {
      const error = err as Error;
      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.groups.rules.fetch'),
        }),
      );
      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const updateGroupInfo = createAsyncThunk(
  'group:updateInfo',
  async function (params: IUpdateGroupInfoParams, thunkAPI) {
    const { groupId, ...groupChanges } = params;
    const { dispatch } = thunkAPI;
    const flowApi = thunkAPI.extra as ControllerFlowAPI;
    const { translations } = flowApi;
    const { t } = translations;

    try {
      const { data } = await flowApi.httpClient.request(
        groups.updateGroup(groupId, groupChanges),
      );

      dispatch(
        application.actions.showToast({
          type: 'success',
          message: t('groups-web.toast.group.update'),
        }),
      );

      return data.group;
    } catch (err) {
      const error = err as Error;
      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.group.update'),
        }),
      );
      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const create = createAsyncThunk(
  'groups:create',
  async function (params: Group, thunkAPI) {
    const { dispatch } = thunkAPI;
    const flowApi = thunkAPI.extra as ControllerFlowAPI;
    const { translations } = flowApi;
    const { t } = translations;

    try {
      const { data } = await flowApi.httpClient.request(groups.create(params));

      dispatch(
        application.actions.showToast({
          type: 'success',
          message: t('groups-web.toast.success.group.create', {
            name: data.group.name,
          }),
        }),
      );

      return data.group;
    } catch (err) {
      const error = err as Error;
      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.group.create', {
            name: params.name,
          }),
        }),
      );
      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const updateGroupSettings = createAsyncThunk(
  'group:updateGroupSettings',
  async function (
    params: { groupId: string; settings: GroupSettings },
    thunkAPI,
  ) {
    const { groupId, settings } = params;
    const { dispatch } = thunkAPI;
    const flowApi = thunkAPI.extra as ControllerFlowAPI;
    const { translations } = flowApi;
    const { t } = translations;

    try {
      const { data } = await flowApi.httpClient.request(
        groups.updateGroup(groupId, {
          settings,
        }),
      );

      return data.group.settings;
    } catch (err) {
      const error = err as Error;
      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.group.update'),
        }),
      );
      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const updateGroupApps = createAsyncThunk(
  'group:updateGroupApps',
  async function (params: { groupId: string; apps: GroupApp[] }, thunkAPI) {
    const flowApi = thunkAPI.extra as ControllerFlowAPI;
    const { dispatch } = thunkAPI;
    const { translations } = flowApi;
    const { t } = translations;
    try {
      return await flowApi.httpClient.request(
        configureApps({
          groupId: params.groupId,
          apps: params.apps,
        }),
      );
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.groupApps.update'),
        }),
      );

      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const query = createAsyncThunk(
  'groups:query',
  async function (params: IQueryGroupsParams, thunkAPI) {
    const {
      title,
      partition,
      limit = 5,
      offset = 0,
      sort = GroupsRequestSort.RECENT_ACTIVITY,
    } = params;
    const flowApi = thunkAPI.extra as ControllerFlowAPI;
    const { translations } = flowApi;
    const { t } = translations;
    const { dispatch } = thunkAPI;

    const order =
      sort === GroupsRequestSort.NAME ? SortOrder.ASC : SortOrder.DESC;

    try {
      const { data } = await flowApi.httpClient.request(
        groups.query({
          partition,
          paging: { offset, limit },
          sort: [{ fieldName: ESortOptions[sort], order }],
          filter: !title
            ? undefined
            : { lowerCaseTitle: { $contains: title.toLowerCase() } },
        }),
      );

      return data;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.groups.query'),
        }),
      );

      flowApi.errorMonitor.captureException(error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const fetchGroup = createAsyncThunk(
  'group:fetch',
  async function (
    params: { groupIdOrSlug: string; autoInviteId?: string },
    thunkAPI,
  ) {
    const { dispatch } = thunkAPI;
    const flowApi = thunkAPI.extra as ControllerFlowAPI;
    const { translations, experiments } = flowApi;
    const { t } = translations;
    const { groupIdOrSlug, autoInviteId } = params;

    try {
      const response = await flowApi.httpClient.request(
        groups.getGroup(groupIdOrSlug, autoInviteId),
      );

      const { group } = response.data;

      if (!group) {
        throw new Error('Group not found');
      }

      const { data } = await flowApi.httpClient.request(
        listApps({
          groupId: group.id as string,
        }),
      );

      const applications = keyBy(data.apps, 'key');

      const apps: GroupApp[] = TABS_WHITELIST.map((key) => ({
        key,
        installed: ENABLED_TABS.includes(key),
        ...applications[key],
      }));

      if (experiments.enabled('specs.groups.EnableFilesTab')) {
        apps.push({
          key: GroupAppKey.FILES_APP,
          installed: false,
          ...applications[GroupAppKey.FILES_APP],
        });
      }

      return {
        apps,
        group,
      };
    } catch (error: any) {
      if (error.response?.status !== 404) {
        dispatch(
          application.actions.showToast({
            type: 'error',
            description: error.message,
            message: t('groups-web.toast.error.group.fetch', {
              slug: groupIdOrSlug,
            }),
          }),
        );

        flowApi.errorMonitor.captureException(error as Error);
      }

      console.error(error);

      return Promise.reject(error);
    }
  },
);

export const updateQuestions = createAsyncThunk(
  'group:questions:fetch:update',
  async function (
    params: CreateOrReplaceAllMembershipQuestionsRequest,
    thunkAPI,
  ) {
    const flowApi = thunkAPI.extra as ControllerFlowAPI;
    const { dispatch } = thunkAPI;
    const { translations } = flowApi;
    const { t } = translations;

    try {
      const { data } = await flowApi.httpClient.request(
        createOrReplaceAllMembershipQuestions(params),
      );
      dispatch(
        application.actions.showToast({
          type: 'success',
          message: t('groups-web.toast.membership-questions-saved'),
        }),
      );
      return data.questions ?? [];
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.questions.update'),
        }),
      );

      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const fetchQuestions = createAsyncThunk(
  'group:questions:fetch',
  async function (groupId: string, thunkAPI) {
    const { dispatch } = thunkAPI;
    const flowApi = thunkAPI.extra as ControllerFlowAPI;
    const { translations } = flowApi;
    const { t } = translations;

    try {
      const { data } = await flowApi.httpClient.request(
        listMembershipQuestions({
          groupId,
        }),
      );

      return data.questions ?? [];
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.group.questions.fetch'),
        }),
      );

      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const fetchJoinRequirements = createAsyncThunk(
  'group:fetchJoinRequirements',
  async function (
    params: GetJoinRequirementsRequest,
    thunkAPI,
  ): Promise<IJoinRequirements> {
    const { dispatch } = thunkAPI;
    const flowApi = thunkAPI.extra as ControllerFlowAPI;
    const { translations, httpClient } = flowApi;
    const { t } = translations;

    let eventsResponse;

    try {
      const { data } = await httpClient.request(getJoinRequirements(params));

      if (data.violation?.eventsOptions?.eventIds) {
        eventsResponse = await getEvents(
          data.violation?.eventsOptions?.eventIds,
          flowApi,
        );
      }

      return {
        ...data,
        violation: {
          ...(data.violation || {}),
          eventsOptions: {
            events: eventsResponse?.data?.events || [],
          },
        },
      };
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.group.fetchJoinRequirements'),
        }),
      );

      flowApi.errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const join = createAsyncThunk(
  'group:join',
  async function (params: JoinRequest, thunkAPI) {
    const { getState, dispatch, extra } = thunkAPI;
    const { httpClient, errorMonitor, translations } =
      extra as ControllerFlowAPI;
    const { t } = translations;

    const user = selectCurrentUser(getState() as IRootState);

    if (!user.loggedIn) {
      await thunkAPI.dispatch(application.thunks.login()).unwrap();
    }

    const agreedToShareProfile = selectShareProfileConsent(
      getState() as IRootState,
      params.groupId,
    );

    try {
      const requirements = await dispatch(
        fetchJoinRequirements(params),
      ).unwrap();

      switch (requirements.violation?.violationType) {
        case ViolationType.ALREADY_JOINED:
          dispatch(application.actions.closeAllDialogs());
          return Promise.reject();

        case ViolationType.NONE:
          if (!agreedToShareProfile) {
            dispatch(
              application.actions.showDialog({
                params,
                dialog: 'disclaimer',
              }),
            );

            return Promise.reject();
          }

          const joinResponse = await httpClient.request(
            membership.joinGroup(params),
          );

          dispatch(application.actions.closeAllDialogs());
          dispatch(
            application.actions.showToast({
              type: 'success',
              message: t('groups-web.toast.group-joined', {
                groupName: joinResponse.data.group.name,
              }),
            }),
          );
          return joinResponse.data.group;

        case ViolationType.ADMIN_APPROVAL:
          if (!agreedToShareProfile) {
            dispatch(
              application.actions.showDialog({
                params,
                dialog: 'disclaimer',
              }),
            );

            return Promise.reject();
          }

          const requestJoinResponse = await httpClient.request(
            membership.requestJoinGroup(params),
          );

          dispatch(application.actions.closeAllDialogs());
          dispatch(
            application.actions.showToast({
              type: 'success',
              message: t('groups-web.toast.group-request-to-join', {
                groupName: requestJoinResponse.data.group.name,
              }),
            }),
          );

          return requestJoinResponse.data.group;

        case ViolationType.MEMBERSHIP_QUESTIONS:
          if (!agreedToShareProfile) {
            dispatch(
              application.actions.showDialog({
                params,
                dialog: 'disclaimer',
              }),
            );

            return Promise.reject();
          }

          dispatch(
            application.actions.showDialog({
              params,
              dialog: 'groupQuestions',
            }),
          );
          return Promise.reject();

        case ViolationType.EVENTS:
          dispatch(
            application.actions.showDialog({
              params,
              dialog: 'eventsRestriction',
            }),
          );
          return Promise.reject();

        case ViolationType.PRICING_PlANS:
          dispatch(
            application.actions.showDialog({
              params,
              dialog: requirements.violation?.pricingPlansOptions?.futurePlans
                ?.length
                ? 'futurePlanDialog'
                : 'paidPlansRestriction',
            }),
          );
          return Promise.reject();

        case ViolationType.NOT_LOGGED_IN:
        case ViolationType.SECRET_GROUP:
        default:
          return Promise.reject();
      }
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.group.join'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const leave = createAsyncThunk(
  'group:leave',
  async function (groupId: string, thunkAPI) {
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as ControllerFlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      const { data } = await httpClient.request(membership.leaveGroup(groupId));

      return data.group;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.group.leave'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const remove = createAsyncThunk(
  'group:remove',
  async function (groupId: string, thunkAPI) {
    const flowApi = thunkAPI.extra as ControllerFlowAPI;
    const { t } = flowApi.translations;

    const groupName =
      selectGroupName(thunkAPI.getState() as IRootState, groupId) ?? '';

    try {
      const { data } = await flowApi.httpClient.request(
        deleteGroup({ groupId }),
      );

      thunkAPI.dispatch(
        application.actions.showToast({
          type: 'success',
          message: t('groups-web.group.actions.delete.success', {
            groupName,
          }),
        }),
      );

      return {
        group: data.group,
      };
    } catch (err) {
      const error = err as Error;

      thunkAPI.dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.group.delete'),
        }),
      );

      flowApi.errorMonitor.captureException(error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const cancelRequest = createAsyncThunk(
  'group:cancelRequest',
  async function (groupId: string, thunkAPI) {
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as ControllerFlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      const { data } = await httpClient.request(
        membership.cancelRequest(groupId),
      );

      return data.group;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.group.cancelRequest'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const fetchNotificationSettings = createAsyncThunk(
  'group:fetchNotificationSettings',
  async function (params: GetNotificationSettingsRequest, thunkAPI) {
    const {
      httpClient,
      errorMonitor,
      translations: { t },
    } = thunkAPI.extra as ControllerFlowAPI;

    try {
      const { data } = await httpClient.request(
        notificationSettings.getNotificationSettings(params),
      );

      return data.settings || [];
    } catch (err) {
      const error = err as Error;

      thunkAPI.dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.group.fetchNotificationSettings'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const updateNotificationSettings = createAsyncThunk(
  'group:updateNotificationSettings',
  async function (params: UpdateNotificationSettingsRequest, thunkAPI) {
    const {
      httpClient,
      errorMonitor,
      translations: { t },
    } = thunkAPI.extra as ControllerFlowAPI;

    try {
      await httpClient.request(
        notificationSettings.updateNotificationSettings(params),
      );

      return params.settings || [];
    } catch (err) {
      const error = err as Error;

      thunkAPI.dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.group.updateNotificationSettings'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

function getEvents(eventIds: string[], flowApi: ControllerFlowAPI) {
  return flowApi.httpClient.request(
    queryEventsV2({
      facet: ['status'],
      fieldset: [
        EventFieldset.DETAILS,
        EventFieldset.REGISTRATION,
        EventFieldset.URLS,
      ],
      query: {
        sort: [{ fieldName: 'start', order: SortOrder.ASC }],
        paging: {
          limit: 100,
        },
        filter: {
          eventId: {
            $hasSome: eventIds,
          },
          status: {
            $ne: 'CANCELED',
          },
        },
      },
    }),
  );
}
