import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import {
  RequestStatus,
  OwnershipFilter,
} from '@wix/ambassador-social-groups-v2-group-request/types';
import type { Group } from '@wix/ambassador-social-groups-v2-group/types';

import * as api from 'api/group-requests/group-requests.api';
import { selectIsSiteAdmin } from 'store/application/selectors';

import * as application from '../application';
import { IRootState } from '../types';

import { IFetchParams, IDeclineParams } from './types';

export const create = createAsyncThunk(
  'group-requests:create',
  async function (group: Group, thunkAPI) {
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as ControllerFlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      const { data } = await httpClient.request(api.create(group));

      return data;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.group-requests.create', {
            name: group.name,
          }),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const cancel = createAsyncThunk(
  'group-requests:cancel',
  async function (id: string, thunkAPI) {
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as ControllerFlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      await httpClient.request(api.remove(id));
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.group-requests.cancel'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const decline = createAsyncThunk(
  'group-requests:decline',
  async function (params: IDeclineParams, thunkAPI) {
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as ControllerFlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      await httpClient.request(api.decline(params.id, params.reason));
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.group-requests.decline'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const approve = createAsyncThunk(
  'group-requests:approve',
  async function (id: string, thunkAPI) {
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as ControllerFlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    try {
      const { data } = await httpClient.request(api.approve(id));

      return data.group;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.group-requests.approve'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);

export const fetch = createAsyncThunk(
  'group-requests:fetch',
  async function (params: IFetchParams, thunkAPI) {
    const { httpClient, errorMonitor, translations } =
      thunkAPI.extra as ControllerFlowAPI;
    const { dispatch } = thunkAPI;
    const { t } = translations;

    const isSiteAdmin = selectIsSiteAdmin(thunkAPI.getState() as IRootState);

    try {
      const { data } = await httpClient.request(
        api.list({
          ownershipFilter: isSiteAdmin
            ? OwnershipFilter.ALL
            : OwnershipFilter.CURRENT_MEMBER,
          query: {
            filter: {
              status: RequestStatus.PENDING,
            },
            paging: {
              limit: params.limit,
              offset: params.offset,
            },
          },
        }),
      );

      return data;
    } catch (err) {
      const error = err as Error;

      dispatch(
        application.actions.showToast({
          type: 'error',
          description: error.message,
          message: t('groups-web.toast.error.group-requests.fetch'),
        }),
      );

      errorMonitor.captureException(error as Error);
      console.error(error);
      return Promise.reject(error);
    }
  },
);
