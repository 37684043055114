import type { ControllerParams } from '@wix/yoshi-flow-editor';

import {
  CommentsControllerApi,
  initializeCommentsController,
  PaginationState,
} from '@wix/comments-ooi-client/controller';

import type { IFeedItem } from 'api/feed/types';

import type { IRootStore } from 'store/index';
import * as feed from 'store/feed';

export function CommentsVM(params: ControllerParams, store: IRootStore) {
  let api: CommentsControllerApi;
  let isReady = false;

  return {
    _: {
      comments: {
        init,
        bind,
        fetch,
        getApi() {
          return api;
        },
        dispose() {
          api?.unmountAllResources();
        },
      },
    },
    comments$: {
      openCommentBox(
        resourceId: string,
        options?: { shouldScroll?: boolean; shouldFocus?: boolean },
      ) {
        api?.affect.tryOpenCommentBox(resourceId, options);
      },
    },
  };

  function bind() {
    if (isReady || !api) {
      return;
    }

    api.watch.pagination.onChange(handleCommentsChange);
    api.bindStateToSetProps();

    isReady = true;
  }

  async function init() {
    if (api) {
      return api;
    }

    api = await initializeCommentsController(params.controllerConfig, {
      shouldAutoBindStateToSetProps: false,
      appDefinitionId: params.controllerConfig.appParams.appDefinitionId,
      httpClient: params.flowAPI.httpClient,
    });

    return api;
  }

  async function fetch(resources?: IFeedItem[]): Promise<void> {
    if (!api || !resources?.length) {
      return;
    }

    return api.bulkFetchComments(
      resources.map((resource) => ({
        resourceId: resource.feedItemId as string,
        ctxFields: {
          contextType: 'postId',
          claims: ['wix.feed.FeedAccess'],
          contextId: resource.applicationContext?.contextId as string,
        },
        pagination: {
          replyShowMoreLimit: 10,
          initialPage: {
            commentLimit: 1,
            replyLimit: 0,
            offset: 0,
          },
          pagination: {
            commentLimit: 20,
            replyLimit: 0,
          },
        },
      })),
    );
  }

  function handleCommentsChange(paginationState: PaginationState) {
    return store.dispatch(
      feed.actions.updateTotalComments(
        Object.fromEntries(
          Object.entries(paginationState).filter((data) => {
            const [, state] = data;

            return state.type === 'READY';
          }),
        ),
      ),
    );
  }
}

export type ICommentsVM = ReturnType<typeof CommentsVM>;
